<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const VITE_ASSET_URL = import.meta.env.VITE_ASSET_URL

const { t } = useI18n()

</script>

<template>
    <div id="info-app-to" style="height: 70px;background-color:#f6fbfd !important;"></div>
    <!-- download Section Start -->
    <div id="info-app" class="video-promo downloadinfo py-5">
      <div class="container">
        <div class="row py-0 pb-4">
          <div class="col-lg-7 col-md-12 col-xs-12" >
            <div class="download-wrapper animate__animated animate__fadeInLeft animate__slow wow fadeInLeft " data-wow-delay="0.2s" style="position: relative;">
              <div class="video-promo art p-3 text-center d-none d-sm-none d-md-block d-lg-block d-xl-block"
                :style="{
                    position: 'absolute',
                    top: '0.5rem',
                    left: '2rem',
                    transform: 'rotate(-5deg) skew(-5deg)',
                    color: '#fff',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)'
                }">
                <h4 style="color: #191c1e;">{{ t("w_txt_home_infoappIntroductoryOffer") }}</h4>
                <h6 style="color:white">
                  {{ t("w_txt_home_infoappIntroductoryOfferDescriptionStart")}} <br/>{{ t("w_txt_home_infoappIntroductoryOfferDescriptionEnd") }}
                </h6>
              </div>
             
              <!-- header of the pricing table seperated to have better style -->
              <div class="d-none d-md-block">
                <div class="d-flex mt-3">
                  <div style="width: 60%"></div>
                  <div
                    class="text-center p-1"
                    :style="{
                        width: '20%',
                        backgroundColor: '#f8f0e4',
                        borderRadius: '15px 15px 0 0',
                        color: '#233648',
                        border: '4px solid #f0a62f'
                    }">
                    <h6 class="p-0 m-0 font-weight-bold">
                      {{ t("w_txt_home_infoappRegistration") }}
                    </h6>
                    <small>{{ t("w_txt_home_infoappRegistrationFree") }}</small>
                    <span><h4>{{ t("w_txt_home_infoappRegistrationFees") }} </h4></span>
                  </div>
                  <div
                    class="text-center p-1"
                    :style="{
                        width: '20%',
                        backgroundColor: '#d6dfe2',
                        borderRadius: '15px 15px 0 0',
                        color: '#233648',
                        border: '4px solid #2da7d4'
                    }">
                    <h6 class="p-0 m-0 font-weight-bold">{{ t("w_txt_home_monthlySubscription") }} </h6>
                    <small>{{ t("w_txt_home_infoappMonthlySubscriptionNoObligation") }}</small><br />
                    <h4 class="m-0 p-0">{{ t("w_txt_home_infoappMonthlySubscriptionFees") }} </h4>
                    <span
                      :style="{
                          fontSize: 'smaller',
                          position: 'relative',
                          bottom: 0,
                          right: '1px' 
                      }">{{ t("w_txt_home_infoappMonthlySubscriptionHT") }} </span
                    >
                  </div>
                </div>

                <table
                  class="table text-dark text-center princing-table mb-0"
                  style="background-color: aliceblue"
                >
                  <tbody>
                    <tr>
                      <td width="60%" class="text-left addPadding">
                        {{ t("w_txt_home_infoappTopelaProfilePublication") }}
                      </td>
                      <td width="20%" class="p-0 first-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                      <td width="20%" class="p-0 second-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left addPadding">{{ t("w_txt_home_infoappContactWithCustomers") }} </td>
                      <td class="p-0 first-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                      <td class="p-0 second-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left addPadding">{{ t("w_txt_home_infoappSmartNotepad") }}</td>
                      <td class="p-0 first-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                      <td class="p-0 second-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left addPadding">{{ t("w_txt_home_infoappQuoteSimulation") }}</td>
                      <td class="p-0 first-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                      <td class="p-0 second-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left addPadding">
                        {{ t("w_txt_home_infoappConnectingAndManaginCustomers") }}
                      </td>
                      <td class="p-0 first-column-item">
                        <span class="text-secondary launching-btn">&#9679;</span>
                      </td>
                      <td class="p-0 second-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left addPadding">{{ t("w_txt_home_infoappCreateAndSendQuotes") }} </td>
                      <td class="p-0 first-column-item">
                        <span class="text-secondary launching-btn">&#9679;</span>
                      </td>
                      <td class="p-0 second-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left addPadding">{{ t("w_txt_home_infoappWorksiteManagement") }} </td>
                      <td class="p-0 first-column-item">
                        <span class="text-secondary launching-btn">&#9679;</span>
                      </td>
                      <td class="p-0 second-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left addPadding">
                        {{ t("w_txt_home_infoappInvoicesTracking") }}
                      </td>
                      <td class="p-0 first-column-item">
                        <span class="text-secondary launching-btn">&#9679;</span>
                      </td>
                      <td class="p-0 second-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left addPadding">
                        {{ t("w_txt_home_infoappRealTimeNotifications") }}
                      </td>
                      <td class="p-0 first-column-item">
                        <span class="text-secondary launching-btn">&#9679;</span>
                      </td>
                      <td class="p-0 second-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="d-block d-md-none" style="overflow-x: auto; width: 100%; white-space: nowrap;scrollbar-width: none;">
                <div class="d-flex mt-3" style="width: 150%; overflow-x: hidden;">
                  <div style="width: 60%">
                    <div style="height:20%"></div>
                    <div style="width: 100%;height:80% !important" class="video-promo art p-3 text-center"
                      :style="{
                        color: '#fff',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                      }">
                      <h4 style="color: #191c1e;">{{ t("w_txt_home_infoappIntroductoryOffer") }}</h4>
                      <h6 style="color:white">
                        {{ t("w_txt_home_infoappIntroductoryOfferDescriptionStart") }} <br/>{{ t("w_txt_home_infoappIntroductoryOfferDescriptionEnd") }}
                      </h6>
                    </div>
                  </div>
                  <div
                    class="text-center p-2 m-0 classElement1"
                    :style="{
                      width: '20%',
                      backgroundColor: '#f8f0e4',
                      borderRadius: '15px 15px 0 0',
                      color: '#233648',
                      border: '4px solid #f0a62f',
                    }">
                    
                    <div style="word-wrap: break-word !important;white-space: pre-wrap;">
                      <h6 class="p-0 m-0 font-weight-bold">
                        {{ t("w_txt_home_infoappRegistration") }}
                      </h6>
                    </div>
                    <small>{{ t("w_txt_home_infoappRegistrationFree") }}</small>
                    <span><h4>{{ t("w_txt_home_infoappRegistrationFees") }} </h4></span>
                  </div>
                  <div
                    class="text-center p-1 m-0 classElement2"
                    :style="{
                      width: '20%',
                      backgroundColor: '#d6dfe2',
                      borderRadius: '15px 15px 0 0',
                      color: '#233648',
                      border: '4px solid #2da7d4'
                    }">
                   
                    <div style="word-wrap: break-word !important;white-space: pre-wrap;">
                      <h6 class="p-0 m-0 font-weight-bold">
                        {{ t("w_txt_home_monthlySubscription") }} 
                      </h6>
                    </div>
                    <small style="word-wrap: break-word !important;white-space: pre-wrap;">{{ t("w_txt_home_infoappMonthlySubscriptionNoObligation") }}</small><br />
                    <h4 class="m-0 p-0">{{ t("w_txt_home_infoappMonthlySubscriptionFees") }} </h4>
                    <span
                      :style="{
                        fontSize: 'smaller',
                        position: 'relative',
                        bottom: 0,
                        right: '1px'
                      }">{{ t("w_txt_home_infoappMonthlySubscriptionHT") }} </span
                    >
                  </div>
                </div>
                <table
                  class="table text-dark text-center princing-table mb-0"
                  style="background-color: aliceblue; width: 150%;white-space: nowrap;"
                >
                  <tbody>
                    <tr>
                      <td width="60%" class="text-left addPadding">
                        {{ t("w_txt_home_infoappTopelaProfilePublication") }}
                      </td>
                      <td width="20%" class="p-0 first-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                      <td width="20%" class="p-0 second-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="60%" class="text-left addPadding">{{ t("w_txt_home_infoappContactWithCustomers") }} </td>
                      <td width="20%" class="p-0 first-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                      <td width="20%" class="p-0 second-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="60%" class="text-left addPadding">{{ t("w_txt_home_infoappSmartNotepad") }}</td>
                      <td width="20%" class="p-0 first-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                      <td width="20%" class="p-0 second-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="60%" class="text-left addPadding">{{ t("w_txt_home_infoappQuoteSimulation") }}</td>
                      <td width="20%" class="p-0 first-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                      <td width="20%" class="p-0 second-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="60%" class="text-left addPadding">
                        {{ t("w_txt_home_infoappConnectingAndManaginCustomers") }}
                      </td>
                      <td width="20%" class="p-0 first-column-item">
                        <span class="text-secondary launching-btn">&#9679;</span>
                      </td>
                      <td width="20%" class="p-0 second-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="60%" class="text-left addPadding">{{ t("w_txt_home_infoappCreateAndSendQuotes") }} </td>
                      <td width="20%" class="p-0 first-column-item">
                        <span class="text-secondary launching-btn">&#9679;</span>
                      </td>
                      <td width="20%" class="p-0 second-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="60%" class="text-left addPadding">{{ t("w_txt_home_infoappWorksiteManagement") }} </td>
                      <td width="20%" class="p-0 first-column-item">
                        <span class="text-secondary launching-btn">&#9679;</span>
                      </td>
                      <td width="20%" class="p-0 second-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="60%" class="text-left addPadding">
                        {{ t("w_txt_home_infoappInvoicesTracking") }}
                      </td>
                      <td width="20%" class="p-0 first-column-item">
                        <span class="text-secondary launching-btn">&#9679;</span>
                      </td>
                      <td width="20%" class="p-0 second-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="60%" class="text-left addPadding">
                        {{ t("w_txt_home_infoappRealTimeNotifications") }}
                      </td>
                      <td width="20%" class="p-0 first-column-item">
                        <span class="text-secondary launching-btn">&#9679;</span>
                      </td>
                      <td width="20%" class="p-0 second-column-item">
                        <span class="text-success launching-btn">&#10003;</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <span
                class="mb-4"
                style="font-style: italic; font-size: smaller; color: #fff"
                >{{ t("w_txt_home_infoappPayUnsubcribeWhenYouWant") }}
              </span>
            </div>
          </div>
          <div class="col-lg-5 col-md-12 col-xs-12">
            <div
              class="download-thumb wow animate__animated animate__fadeInRight animate__slow fadeInRight text-center py-4"
              data-wow-delay="0.2s"
            >
              <h3 class="pb-3" style="color: #fff">
                {{ t("w_txt_home_infoappDownloadNow") }}
              </h3>
              <img class="img-fluid" :src="VITE_ASSET_URL + 'assets/public/assets/img/mac.webp'" alt="télécharger l'application topela,artisan, travaux, gestion, peintre, plombier, menuisier, électricien, devis, prestation, facturation, paiement, client, rénovation, construction"/>
            </div>
            <div class="video-promo-content">
              <p class="text-center py-3">
                {{ t("w_txt_home_infoappTopelAppDescription") }}
              </p>
            </div>
            <div class="row">
              <div class="col-6 d-flex justify-content-center" >
                <a
                  :href="t('g_txt_topela_app_store_link')"
                  target="_blank"
                  class="market-btn apple-btn w-100"
                  role="button"
                >
                  <span class="market-button-subtitle">{{ t("w_txt_home_infoappDownloadOn") }}</span>
                  <span class="market-button-title">{{ t("w_act_appstore") }} </span>
                </a>
              </div>
              <div class="col-6 d-flex justify-content-center">
                <a
                  :href="t('g_txt_topela_play_store_link')"
                  target="_blank"
                  class="market-btn google-btn w-100"
                  role="button"
                >
                  <span class="market-button-subtitle">{{ t("w_txt_home_infoappDownloadOn") }}</span>
                  <span class="market-button-title">{{ t("w_txt_home_infoappDownloadOnGooglePlay") }}  </span>
                </a>
              </div>
            </div>
            <div id="client-user-replace"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- download Section Start -->
</template>

<style scoped>
.pricing-table {
  border: none;
}

/* If you also want to remove the inner border of the cells, you can add this style */

.pricing-table th {
  border: none;
}

.pricing-table td:nth-child(2n) {
  background-color: #f8f0e4;
}

.pricing-table td:nth-child(3n) {
  background-color: #d6dfe2;
}

.pricing-table td .text-success,
.pricing-table td .text-secondary {
  font-weight: bolder !important;
  font-size: x-large !important;
}

.launching-btn {
  font-weight: bolder !important;
  font-size: x-large !important;
}

.first-column-item{
    background-color: #f8f0e4;
}
.second-column-item{
    background-color: #d6dfe2;
}

.addPadding{
  padding: 0.75rem;
}

</style>